// Copyright 2018 The Distill Template Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/*
  Try to only reorder things that MAY be user defined.
  Try to use templates etc to define the order of our own tags.
*/

export default function render(dom) {
  const head = dom.head;

  const metaIE = head.querySelector('meta[http-equiv]');
  head.insertBefore(metaIE, head.firstChild);

  const metaViewport = head.querySelector('meta[name=viewport]');
  head.insertBefore(metaViewport, head.firstChild);

  const metaCharset = head.querySelector('meta[charset]');
  head.insertBefore(metaCharset, head.firstChild);
}
