// Copyright 2018 The Distill Template Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { parseBibtex } from '../helpers/bibtex';
import fs from 'fs';
import { parseBibliography } from '../components/d-bibliography';

export default function(dom, data) {
  const bibliographyTag = dom.querySelector('d-bibliography');
  if (!bibliographyTag) {
    console.warn('No bibliography tag found!');
    return;
  }

  const src = bibliographyTag.getAttribute('src');
  if (src) {
    const path = data.inputDirectory + '/' + src;
    const text = fs.readFileSync(path, 'utf-8');
    const bibliography = parseBibtex(text);
    const scriptTag = dom.createElement('script');
    scriptTag.type = 'text/json';
    scriptTag.textContent = JSON.stringify([...bibliography]);
    bibliographyTag.appendChild(scriptTag);
    bibliographyTag.removeAttribute('src');
  }

  data.bibliography = parseBibliography(bibliographyTag);
}
